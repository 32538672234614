<template>
  <div>
    <div class="csat-options" v-if="csatData.length">
      <div class="select-product" v-if="csatData.length && driverView === 2">
        <SingleSelectorVue
                class="product"
                label=""
                placeholder="Select product"
                :list="selectedProducts"
                :showRadio="true"
                :selected="localSelectedProduct"
                :clearSelection="true"
                @onChange="onChangeProduct" />
        <div class="other-products">
          <span>vs</span>
          <div class="others">Others</div>
        </div>
      </div>
      <div v-if="csatData.length && driverView !== 2" class="toggle-button">
        <div class="option-cover">
          <label for="showTotal" class="custom-checkbox">
            <input type="checkbox" name="showTotal" id="showTotal" @click="toggleShowTotal" :checked="showTotal">
            <div class="checkmark"></div>
            <p>Show Product Total</p>
          </label>
        </div>
      </div>
      <div v-if="csatData.length" class="toggle-button">
        <dropdown-wrap @onClickOutside="onClickOutside">
          <div class="option-cover">
              <b-button @click="openDriverViewDropdown" class="driver-display-button">
                  <div class="inside-sort-button inside">
                  <p>{{getName}}</p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="7.072" height="7.072" viewBox="0 0 7.072 7.072" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#a8aab7" d="M3.889 6.718a.5.5 0 01-.707 0L.353 3.889a.5.5 0 11.708-.707l2.474 2.475L6.01 3.182a.5.5 0 11.707.707z"/></svg>
                  </div>
              </b-button>
              <div v-if="dropdownOpen" class="view-dropdown">
                  <p class="option-header">Core Capabilities:</p>
                  <div class="view-options">
                    <div class="default-sort"  @click.prevent="onChangeOption(0)">
                      <label for="rating" class="custom-radio">
                        <input type="radio" name="displayView" id="rating" :checked="localSelected == 0">
                        <div class="radio-checkmark"></div>
                        <p>By Ratings</p>
                      </label>
                    </div>
                    <div class="default-sort"  @click.prevent="onChangeOption(1)">
                      <label for="reaction" class="custom-radio">
                        <input type="radio" name="displayView" id="reaction" :checked="localSelected == 1">
                        <div class="radio-checkmark"></div>
                        <p>By Reactions</p>
                      </label>
                    </div>
                    <p class="option-header">Roadmap:</p>
                    <div class="default-sort"  @click.prevent="onChangeOption(2)">
                      <label for="comparison" class="custom-radio">
                        <input type="radio" name="displayView" id="comparison" :checked="localSelected == 2">
                        <div class="radio-checkmark"></div>
                        <p>Prioritization Matrix</p>
                      </label>
                    </div>
                  </div>
              </div>
          </div>
        </dropdown-wrap>
      </div>
      <div v-if="csatData.length && driverView !== 2" class="csat-sort">
        <div class="csat-sort-inside">
          <global-sort-vue
            :selected="sort"
            :showSortBy="false"
            :options="sortOptions"
            :csatPage="true"
            @onChange="onChangeSelection" />
        </div>
      </div>
    </div>
    <div class="main-card" v-if="csatData.length && driverView != 2">
      <template v-if="!noData">
        <div class="local-loader-container" v-if="csatLoader">
          <div class="inava-loader"></div>
        </div>
        <div class="line-separator-copy"></div>
        <div class="custom-table-container-block-2">
          <table class="csat-table">
            <thead ref="csatTableHeader">
              <tr>
                <th class="filter">
                  CORE CAPABILITIES
                  <img src="../../assets/info-new.svg" alt="" class="info-icon" @mouseenter="tooltip($event, tooltipData, 'in')" @mouseleave="tooltip($event, tooltipData, 'out')"
                  @mousemove="tooltip($event, tooltipData, 'in')" />
                </th>
                <th class="filter" v-if="showTotal">ALL PRODUCT TOTAL</th>
                <th
                  v-for="productName of sortedProducts"
                  :key="productName.id"
                  :class="driverViewClass">
                  <img
                    v-if="
                      (user_preference_mode == 'dark' &&
                        productName.properties &&
                        productName.properties.logo_dark) ||
                      (user_preference_mode == 'light' &&
                        productName.properties &&
                        productName.properties.logo_light)
                    "
                    :src="
                      user_preference_mode == 'dark'
                        ? productName.properties.logo_dark
                        : productName.properties.logo_light
                    "
                    :alt="productName.name"
                    :title="productName.name"
                    class="product-logo" />
                  <span v-else class="product-name">{{ productName.name }}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(name, index) of csatData">
                <tr :key="'A' + index">
                  <td
                    class="row-header tooltip-wrapper"
                    :class="[name.sub_category.length ? 'expand-button has-text-weight-bold' : '']"
                    :helpText="name.helpText"
                    @mouseenter="showTooltip"
                    @mousemove="showTooltip"
                    @mouseleave="hideTooltip"
                    @click="toggleRows(name.topic_id)">
                    {{ name.topic }}
                    <span class="carret" v-if="name.sub_category.length">
                      <svg xmlns="http://www.w3.org/2000/svg" width="7.072" height="7.072" viewBox="0 0 7.072 7.072" v-bind:svg-inline="''" :class="[isExpanded(name.topic_id) ? 'angle-down' : 'angle-right']" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#7f8fa4" d="M6.718 3.184a.5.5 0 010 .707L3.89 6.719a.5.5 0 11-.707-.707l2.475-2.475-2.475-2.475A.5.5 0 113.89.355z"/></svg>
                    </span>
                  </td>
                  <template v-if="showTotal">
                    <td>
                      <NewRowVue
                        :num="{  name: 'ALL PRODUCT TOTAL', id: 0}"
                        :name="name.topic"
                        :products="name.product"
                        :parentId="null"
                        :subTopic="true"
                        :total="true" />
                    </td>
                    <td
                      v-for="num of sortedProducts"
                      :key="num.id">
                      <NewRowVue
                        :num="num"
                        :name="name.topic"
                        :products="name.product"
                        :parentId="null"
                        :subTopic="true"
                        :total="false" />
                    </td>
                  </template>
                  <template v-else>
                    <td
                      v-for="num of sortedProducts"
                      :key="num.id">
                      <NewRowVue
                        :num="num"
                        :name="name.topic"
                        :products="name.product"
                        :parentId="null"
                        :subTopic="true"
                        :total="false" />
                    </td>
                  </template>
                </tr>
                <template v-if="!noData && name.sub_category.length">
                  <div
                    class="subRows-wrapper"
                    v-if="isExpanded(name.topic_id)"
                    style="display: contents"
                    :key="'B' + index">
                    <tr
                      v-for="(category, index) of name.sub_category"
                      :key="'C' + index"
                      class="subRows">
                      <td
                        class="row-header row-header-sub tooltip-wrapper"
                        :helpText="name.helpText"
                        @mouseenter="showTooltip"
                        @mousemove="showTooltip"
                        @mouseleave="hideTooltip">
                        {{ category.topic }}
                      </td>
                      <template v-if="showTotal">
                        <td>
                          <NewRowVue
                            :num="{  name: 'ALL PRODUCT TOTAL', id: 0}"
                            :name="category.topic"
                            :products="category.product"
                            :parentId="category.parent_id"
                            :subTopic="true"
                            :total="true" />
                        </td>
                        <td
                          v-for="num of sortedProducts"
                          :key="num.id">
                          <NewRowVue
                            :num="num"
                            :name="category.topic"
                            :products="category.product"
                            :parentId="category.parent_id"
                            :subTopic="true"
                            :total="false" />
                        </td>
                      </template>
                      <template v-else>
                        <td
                          v-for="num of sortedProducts"
                          :key="num.id">
                          <NewRowVue
                          :num="num"
                          :name="category.topic"
                          :parentId="category.parent_id"
                          :products="category.product"
                          :subTopic="false"
                          :total="false" />
                        </td>
                      </template>
                    </tr>
                  </div>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </template>
      <template v-else>
        <NoDataContainerVue type="cSatDriver" />
      </template>
    </div>
    <div v-if="csatData.length && driverView == 2">
      <div class="kano-chart-container" v-if="!noData">
        <div class="local-loader-container" v-if="kanoLoader || csatLoader">
          <div class="inava-loader"></div>
        </div>
        <kano-chart
          :noData="!kanoPrecheck"
          :data="singleProdData"
          :secondaryData="nMinusOneData"
          :legendsText="bubbleLegends"
          :totalData="sentimentScoreDataTotal"
          :axesArray="avgAxes"
          :hideHeader="true"
          :zoom="true" />
      </div>
      <div v-else>
        <NoDataContainerVue type="cSatDriver" />
      </div>
    </div>
    <div v-if="!csatData.length">
      <NoDataContainerVue type="cSatDriver" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DropdownWrap from '../../components/Common/DropdownWrap.vue'
// import CustomRowVue from '../../components/CsatDrivers/CustomRow.vue'
import NewRowVue from '../../components/CsatDrivers/NewRow.vue'
import NoDataContainerVue from '../../components/Common/NoDataContainer/NoDataContainer.vue'
import { metaTitle } from '../../constant/data'
import GlobalSortVue from '../../components/GlobalSort/GlobalSort.vue'
import tooltip from '../../util/tooltip'
import KanoChart from '../../components/CsatDrivers/KanoChart.vue'
import SingleSelectorVue from '../../components/Dropdowns/SingleSelector.vue'
import { isEqual } from 'lodash'

/** This is the parent component/view for the **CSAT Drivers** page. */
export default {
  name: 'CsatDrivers',
  metaInfo () {
    return {
      title: metaTitle.csatDrivers
    }
  },
  components: {
    // CustomRowVue,
    NoDataContainerVue,
    GlobalSortVue,
    NewRowVue,
    DropdownWrap,
    KanoChart,
    SingleSelectorVue
  },
  data () {
    return {
      noData: false,
      dropdownOpen: false,
      parentTopics: {},
      driverViewList: [
        { name: 'By Ratings', value: 0 },
        { name: 'By Reaction', value: 1 },
        { name: 'Prioritization Matrix', value: 2 }
      ],
      currentSelection: {},
      bubbleLegends: {
        x: 'FEATURE MATURITY',
        y: 'PRODUCT ACCEPTANCE'
      },
      totalData: [],
      nMinusOneData: [],
      singleProdData: [],
      localSelected: 0,
      tooltipData: "Core Capabilities include Standard capabilities (that are generic across product categories) as well as Functional capabilities (capabilities specific to product categories) grouped under 'Functionality'"
    }
  },
  computed: {
    ...mapState('filters', [
      'selectedSourceList',
      'selectedCategories',
      'selectedProducts',
      'sortedProducts',
      'selectedIndustries',
      'selectedCompanies',
      'subCategories',
      'ActiveVocTimePeriod'
    ]),
    ...mapState('csatdriver', [
      'csatDriverSummary',
      'csatData',
      'sortOptions',
      'sort',
      'driverView',
      'localSelectedProduct',
      'sentimentScoreDataTotal',
      'sentimentScoreDataNMinusOne',
      'sentimentScoreDataSingle',
      'showTotal',
      'csatLoader',
      'kanoLoader'
    ]),
    ...mapState('common', ['user_preference_mode', 'currentModule', 'active_page']),
    ...mapState('user', ['userDetails']),
    payloadData () {
      return {
        module: this.currentModule,
        source: this.selectedSourceList.map((item) => item.id),
        products: this.selectedProducts,
        startTime: this.ActiveVocTimePeriod.range[0],
        endTime: this.ActiveVocTimePeriod.range[1],
        industries: this.selectedIndustries.map((item) => item.id),
        companySize: this.selectedCompanies.map((item) => item.id),
        categoryId: this.subCategories,
        subscriptionId: this.selectedCategories.subscriptionId,
        requestingModuleId: this.userDetails.modules.find(m => m.module_name === 'voc').module_id
      }
    },
    getName () {
      // return this.driverViewList.filter((option) => option.value === this.driverView)[0].name
      return this.driverViewList.find((option) => option.value === this.driverView).name
    },
    avgAxes () {
      function avg (arr) {
        let totalX = 0
        let totalY = 0
        for (let i = 0; i < arr.length; i++) {
          totalX += arr[i].avgSatisfaction
          totalY += arr[i].correlationCoef
        }
        const avgX = totalX / arr.length
        const avgY = totalY / arr.length
        return [avgX, avgY]
      }
      const average = avg(this.sentimentScoreDataTotal)
      return [{ is_standard: true, overall_rating: average[1] }, { is_standard: false, overall_rating: average[0] }]
    },
    kanoPrecheck () {
      return (this.sentimentScoreDataTotal.length !== 0)
    },
    driverViewClass () {
      switch (this.driverView) {
        case 1:
          return 'likeView'
        case 2:
          return 'comp-comparison'
        default:
          return 'percentView'
      }
    },
    watchable () {
      return JSON.stringify(this.selectedSourceList) + JSON.stringify(this.selectedIndustries) + JSON.stringify(this.selectedCompanies) + JSON.stringify(this.selectedProducts) + JSON.stringify(this.ActiveVocTimePeriod)
    }
  },
  watch: {
    watchable () {
      if (this.watchable.length) {
        this.getStatistics()
        if (!this.selectedProducts.length || !this.exists(this.localSelectedProduct, this.selectedProducts)) {
          this.updateSelectedProduct({})
        }
      }
      if (this.localSelectedProduct) { this.getKanoData() }
    },
    csatDriverSummary () {
      this.setSummaryData()
    },
    sort () {
      if (this.csatDriverSummary.length) {
        this.setSummaryData()
      }
    },
    csatData () {
      if (this.csatData.length) {
        this.getListOfParentTopics()
        this.noData = false
        this.updateCsatLoader(false)
      } else {
        this.noData = true
      }
    },
    localSelectedProduct (newValue, oldValue) {
      const hasLength = (obj) => {
        // eslint-disable-next-line no-unreachable-loop
        for (const i in obj) {
          return true
        }
        return false
      }
      if (hasLength(newValue)) {
        this.getKanoData()
      } else {
        this.resetKanoData()
      }
    },
    sentimentScoreDataSingle (newValue, oldValue) {
      if (newValue.length) {
        this.singleProdData = newValue
      } else {
        this.singleProdData = this.sentimentScoreDataTotal
      }
      this.updateKanoLoader(false)
    },
    sentimentScoreDataTotal (newValue, oldValue) {
      this.totalData = newValue
      // Will only run once
      if (!this.sentimentScoreDataSingle.length) {
        this.singleProdData = this.sentimentScoreDataTotal
      }
      this.updateKanoLoader(false)
    },
    sentimentScoreDataNMinusOne (newValue, oldValue) {
      this.nMinusOneData = newValue
      this.updateKanoLoader(false)
    },
    driverView () {
      this.checkDataSelection()
      if (this.driverView === 2) {
        this.getStatistics()
      }
    }
  },
  updated () {
    if (sessionStorage.getItem('driverView')) {
      this.localSelected = JSON.parse(sessionStorage.getItem('driverView'))
    }
    if (this.$refs.csatTableHeader) {
      const tableHeader = this.$refs.csatTableHeader
      document.querySelector('.line-separator-copy').style.setProperty('--header-height', tableHeader.clientHeight + 'px')
    }
  },
  mounted () {
    if (sessionStorage.getItem('driverView')) {
      this.localSelected = JSON.parse(sessionStorage.getItem('driverView'))
    }
    this.updateDriverDisplayMode({ page: this.active_page, value: this.localSelected })
    this.getStatistics()
    if (this.sentimentScoreDataTotal.length && !this.singleProdData.length) {
      this.singleProdData = this.sentimentScoreDataTotal
    }
  },
  methods: {
    ...mapActions('csatdriver', [
      'getCsatDriverSummary',
      'updateCsatDriverData',
      'updateSort',
      'updatePercentageMode',
      'getSentimentRatingCorrelationTotal',
      'getSentimentRatingCorrelationNMinusOne',
      'getSentimentRatingCorrelationSingle',
      'updateDriverDisplayMode',
      'updateSelectedProduct',
      'toggleShowTotal',
      'updateCsatLoader',
      'updateKanoLoader'
    ]),
    ...mapActions('analytics', ['updateEvent']),
    tooltip,
    isEqual,
    // /** This method hides/displays the *All Product Total* column in the CSAT Drivers table
    //  * @public
    //  */
    // toggleShowTotal () {
    //   this.showTotal = !this.showTotal
    // },
    /**
     * This method updates the CSAT Driver display. It can show one of the following views:
     * - CSAT Drivers Table (Ratings View)
     * - CSAT Drivers Table (Reactions View)
     * - CSAT Drivers Kano Analysis Chart
     * @param value {Number} 0, 1, 2
     * @public
     */
    onChangeOption (value) {
      this.updateDriverDisplayMode({ page: this.active_page, value: value })
    },
    onClickOutside (event) {
      this.dropdownOpen = false
    },
    /**
     * This methods toggles the dropdown used to select the CSAT Drivers view
     */
    openDriverViewDropdown () {
      this.dropdownOpen = !this.dropdownOpen
      if (this.dropdownOpen) {
        this.updateEvent({
          userId: this.userDetails.userId,
          event: 'click',
          label: 'Driver View Dropdown',
          pageUrl: window.location.href
        })
      }
    },
    /**
     * This method is used to select a single product to be compared against the rest. It is only used on the Kano Analysis view
     * @public
     */
    onChangeProduct (data) {
      this.updateSelectedProduct(data)
      this.currentSelection = data
    },
    /**
     * This method is used to update the sorting for the drivers. It is only used in the table views
     * @public
     */
    onChangeSelection (data) {
      this.updateSort(data)
    },
    /**
     * This method is used to expand the Functionality driver's hidden rows
     * @public
     */
    openFunctionality () {
      this.open = !this.open
    },
    /**
     * This method checks to see if a certain topic is expanded or not.
     * @param id {Number}
     */
    isExpanded (id) {
      return this.parentTopics[id]
    },

    /** Helper function to check if an object exists in some target array
     * @param item {Object} - The object that is being searched for in the list
     * @param target {Array} - The list/array of objects in which we search for the object *item*
     * @public
     */
    exists (item, target) {
      return target.some(obj => this.isEqual(obj, item))
    },
    /**
     * This method generates an object which contains the IDs of topics that have sub topics as keys, and the status of their expansion as a Boolean value.
     * @public
     */
    getListOfParentTopics () {
      const list = this.csatData
      const parentList = {}
      for (const item of list) {
        if (item.sub_category.length > 0) {
          if (this.parentTopics === null) { parentList[item.topic_id] = false } else { parentList[item.topic_id] = this.parentTopics[item.topic_id] }
        }
      }
      this.parentTopics = parentList
    },
    /**
     * This method is used to expand/contract the hidden rows of a parent topic
     * @param id {Number}
     */
    toggleRows (id) {
      this.parentTopics[id] = !this.parentTopics[id]
    },
    /**
     * This method gets all the data required for filling the CSAT Drivers Table
     */
    getStatistics () {
      if (
        this.currentModule === 'voc' &&
        this.selectedSourceList.length &&
        this.selectedProducts.length &&
        this.selectedIndustries.length &&
        this.selectedCategories != null &&
        this.subCategories.length &&
        this.selectedCompanies.length &&
        this.ActiveVocTimePeriod &&
        this.ActiveVocTimePeriod.group_by
      ) {
        this.getCsatDriverSummary(this.payloadData)
        if (this.driverView === 2) {
          this.getSentimentRatingCorrelationTotal(this.payloadData)
        }
      } else {
        this.noData = true
      }
      this.checkDataSelection()
    },
    resetKanoData () {
      this.nMinusOneData = []
      this.getSentimentRatingCorrelationSingle(null)
    },
    /**
     * This method gets all the data required to make the Kano Chart work
     */
    getKanoData () {
      const arr = []
      arr.push(this.localSelectedProduct)
      const singleProd = {
        module: this.currentModule,
        source: this.selectedSourceList.map((item) => item.id),
        products: arr,
        startTime: this.ActiveVocTimePeriod.range[0],
        endTime: this.ActiveVocTimePeriod.range[1],
        industries: this.selectedIndustries.map((item) => item.id),
        companySize: this.selectedCompanies.map((item) => item.id),
        categoryId: this.subCategories,
        subscriptionId: this.selectedCategories.subscriptionId,
        // requestingModuleId: this.userDetails.modules.filter(m => m.module_name === 'voc')[0].module_id
        requestingModuleId: this.userDetails.modules.find(m => m.module_name === 'voc').module_id
      }
      const nMinusOne = this.payloadData
      nMinusOne.products = this.payloadData.products.filter((option) => option.name !== this.localSelectedProduct.name)
      this.getSentimentRatingCorrelationNMinusOne(nMinusOne)
      this.getSentimentRatingCorrelationSingle(singleProd)
    },
    /**
     * This method calculates the total values of all product drivers in the database, to create a new column titled *ALL PRODUCT TOTAL* that is displayed in the CSAT Drivers Table
     * @public
     */
    addAllProduct (arr) {
      let max = 0
      for (const feature of arr) {
        let totalCount = 0
        let cSatAvg = 0
        let dislike = 0
        let like = 0
        let count = 0
        let isStandard = false
        if (feature.product) {
          for (const product in feature.product) {
            if (feature.product[product].total_count > 0) {
              isStandard = feature.product[product].is_standard
              count++
              totalCount += feature.product[product].total_count
              cSatAvg += feature.product[product].c_sat_avg
              dislike += feature.product[product].dislike_count
              like += feature.product[product].like_count
            }
          }
          feature.product['ALL PRODUCT TOTAL'] = {
            total_count: totalCount,
            c_sat_avg: cSatAvg / count,
            dislike: dislike / totalCount,
            like: like / totalCount,
            topic_name: feature.topic,
            topic_id: feature.topic_id,
            parent_id: feature.parent_id,
            is_standard: isStandard
          }
        }
        max = Math.max(max, totalCount)
      }
      for (const feature of arr) {
        if (feature.product) {
          feature.product['ALL PRODUCT TOTAL'].max = max
        }
      }
      return arr
    },
    /** Unsure as to what this does */
    setSummaryData () {
      const uniqueTopics = [
        ...new Set(this.csatDriverSummary.map((item) => item.topic_id))
      ]
      let temp = 0 // {};
      for (const product of this.csatDriverSummary) {
        if (temp < Number(product.total_count)) {
          temp = Number(product.total_count)
        }
      }
      const csatData = []
      for (const topic of uniqueTopics) {
        const topicData = {
          topic,
          product: {},
          sub_category: [],
          help_text: null
        }
        for (const product of this.csatDriverSummary) {
          if (product.topic_id === topic) {
            product.max = temp
            topicData.parent_id = product.parent_id
            topicData.topic_id = product.topic_id
            topicData.topic = product.topic_name
            topicData.product[product.product_name] = product
            topicData.helpText = product.help_text
          }
        }
        csatData.push(topicData)
      }
      const withoutParentId = []
      const withParentId = []
      for (const product of csatData) {
        if (product.parent_id == null) {
          withoutParentId.push(product)
        } else {
          withParentId.push(product)
        }
      }
      if (withParentId.length) {
        for (const withoutParent of withoutParentId) {
          const child = withParentId.filter(
            (data) => data.parent_id === withoutParent.topic_id
          )
          if (child.length) {
            withoutParent.sub_category = child
          }
        }
      }
      const withOwnedProduct = []
      for (const product of withoutParentId) {
        if (product.product[this.sortedProducts[0].name] === undefined) {
          product.product[this.sortedProducts[0].name] = {
            c_sat_avg: 0,
            help_text: null,
            max: 0,
            dislike: 0,
            parent_id: null,
            product_name: this.sortedProducts[0].name,
            topic_id: product.topic_id,
            topic_name: product.topic,
            total_count: 0,
            like: 0
          }
        }
        withOwnedProduct.push(product)
      }
      let finalFilter = withOwnedProduct.sort((a, b) => {
        return b.product[this.sortedProducts[0].name][this.sort.type] >
          a.product[this.sortedProducts[0].name][this.sort.type]
          ? this.sort.orderBy === 'asc'
            ? -1
            : 1
          : b.product[this.sortedProducts[0].name][this.sort.type] <
            a.product[this.sortedProducts[0].name][this.sort.type]
            ? this.sort.orderBy === 'asc'
              ? 1
              : -1
            : 0
      })
      for (const topic of finalFilter) {
        if (topic.sub_category.length) {
          for (const product of topic.sub_category) {
            if (product.product[this.sortedProducts[0].name] === undefined) {
              product.product[this.sortedProducts[0].name] = {
                c_sat_avg: 0,
                help_text: null,
                max: 0,
                dislike: 0,
                parent_id: null,
                product_name: this.sortedProducts[0].name,
                topic_id: product.topic_id,
                topic_name: product.topic,
                total_count: 0,
                like: 0
              }
            }
          }
          topic.sub_category.sort((a, b) => {
            return b.product[this.sortedProducts[0].name][this.sort.type] >
              a.product[this.sortedProducts[0].name][this.sort.type]
              ? this.sort.orderBy === 'asc'
                ? -1
                : 1
              : b.product[this.sortedProducts[0].name][this.sort.type] <
                a.product[this.sortedProducts[0].name][this.sort.type]
                ? this.sort.orderBy === 'asc'
                  ? 1
                  : -1
                : 0
          })
        }
      }

      finalFilter = this.addAllProduct(finalFilter)
      for (const subTopic of finalFilter) {
        if (subTopic.sub_category && subTopic.sub_category.length) {
          subTopic.sub_category = this.addAllProduct(subTopic.sub_category)
        }
      }
      this.updateCsatDriverData(finalFilter)
    },
    /**
     * This method makes sure that the minimum data needed to create the table/chart is present. If it's not, then the *No Data* container is shown instead.
     * @public
     */
    checkDataSelection () {
      if (
        !(
          this.selectedSourceList.length &&
          this.selectedProducts.length &&
          this.selectedIndustries.length &&
          this.selectedCompanies.length
        )
      ) {
        this.noData = true
      }
    },
    /**
     * This method shows the tooltip. Intended for the CSAT Drivers table. Currently not in use
     */
    showTooltip (event, d) {
      this.tooltipFun(event, d, 'in')
    },
    /**
     * This method hides the tooltip. Intended for the CSAT Drivers table. Currently not in use
     */
    hideTooltip (event, d) {
      this.tooltipFun(event, d, 'out')
    },
    /**
     * This method controls the content shown in the tooltip
     */
    tooltipFun (event, d, type) {
      let data = ''
      switch (type) {
        case 'in':
          data = event.target.getAttribute('helptext')
          break
      }
      if (data || type === 'out') {
        tooltip(event, data, type, true)
      }
    }
  }
}
</script>
<style lang="scss" src="./CSatDrivers.scss" scoped />
